.main-section {
  background-color: #000000;
  height: 470px;
}

.main-section .content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
