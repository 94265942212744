.menu-item {
  color: #ffffff;
  margin-left: 36px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.menu-item:first-of-type {
  margin: 0;
}

.menu-item:hover {
}
