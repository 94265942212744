.team-file-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  flex-shrink: 0;
  margin-left: 100px;
}

.team-file-wrapper:first-of-type {
  margin-left: 0;
}

.team-file-wrapper .team-file-profile {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: solid 4px #000000;
  margin-bottom: 36px;
}

.team-file-wrapper .team-file-name {
  font-family: 'Futura', sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.team-file-wrapper .team-file-description {
  color: #9b9b9b;
  margin: 16px 0;
}

@media (max-width: 600px) {
  .team-file-wrapper {
    margin-left: 0;
    max-width: 400px;
    margin-bottom: 80px;
  }
}

@media (max-width: 500px) {
  .team-file-wrapper {
    max-width: initial;
    width: 100%;
  }
}
