footer {
  width: 100%;
  height: 160px;
  background: #000000;
  padding: 34px 0;
}

footer .footer-actions-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

footer .footer-action {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

footer .footer-action:after {
  display: block;
  content: '|';
  color: #ffffff;
  margin: 0 12px;
}

footer .footer-action:last-of-type:after {
  display: none;
}

@media (max-width: 500px) {
  footer .footer-actions-wrapper {
    justify-content: center;
  }
}
