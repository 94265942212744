.menu {
  height: 80px;
  background-color: #000000;
  position: fixed;
  width: 100%;
  z-index: 1024;
}

.menu > .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.menu .logo-wrapper {
  background: #ffffff;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.menu .logo {
  max-width: 118px;
}

@media (max-width: 680px) {
  .menu nav {
    display: none;
  }
}
