.price-item-wrapper {
  width: 100%;
  text-align: left;
  position: relative;
  margin: 0 0 24px 0;
  display: flex;
  align-items: flex-end;
}

.price-item-wrapper:before {
  content: '';
  height: 0;
  line-height: 0;
  display: block;
  order: 1;
  flex: 1;
  border-bottom: 2px dotted #ddd;
  margin-bottom: 4px;
}

.price-item-wrapper .price-item-label {
  background: inherit;
  display: inline;
  padding-right: 0.2rem;
}

.price-item-wrapper .price-item-amount {
  padding-left: 0.2rem;
  text-align: right;
  order: 2;
}

.price-item-wrapper .price-item-amount:after {
  content: '€';
}
