.slider-frame,
.slider-list,
.slider-slide {
  height: 100% !important;
}

.slider-slide {
  background: #000000;
}

.slider-frame img {
  height: 100%;
  width: auto !important;
  object-fit: cover;
  margin: auto;
}

.slider > .slider-frame > .slider-list {
  width: auto !important;
}

.slider-button {
  border: 0px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 10px;
  outline: 0px;
  opacity: 1;
  cursor: pointer;
}

.slider-button img {
  width: 12px;
}
