.section-header-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-header-decoration img {
  margin: 0 26px;
}

.section-header-decoration:after,
.section-header-decoration:before {
  content: '';
  display: block;
  width: 160px;
  height: 2px;
  background: #333333;
}

@media (max-width: 400px) {
  .section-header-decoration:after,
  .section-header-decoration:before {
    width: 100px;
  }
}
