.content {
  max-width: 1160px;
  width: 100%;
  padding: 0 20px;
  margin: auto;
}

@media (max-width: 1024px) {
  .content {
    width: calc(100% - 80px);
  }
}

@media (max-width: 800px) {
  .content {
    width: calc(100% - 40px);
  }
}
