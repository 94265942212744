.info-section .grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(212px, 260px));
  grid-auto-rows: minmax(212px, 1fr);
  grid-gap: 26px;
  grid-template-areas:
    'box1 slider slider slider'
    'box2 slider slider slider'
    'box3 box4 box5 box6';
  justify-content: center;
  margin: 120px 0;
}

.info-section .grid *[class^='grid-box'] {
  width: 100%;
  height: 100%;
  position: relative;
}

.info-section .grid *[class^='grid-box']:before {
  content: '';
  width: 100%;
  display: block;
  margin-top: 100%;
}

.info-section .grid *[class^='grid-box'] > .grid-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grid-box1 {
  grid-area: box1;
}

.grid-box2 {
  grid-area: box2;
}

.grid-box3 {
  grid-area: box3;
}

.grid-box4 {
  grid-area: box4;
}

.grid-box5 {
  grid-area: box5;
}

.grid-box6 {
  grid-area: box6;
}

.slider-box {
  grid-area: slider;
  position: relative;
}

.box-header {
  margin: 0 0 8px;
  font-size: 24px;
}

.box-text {
  color: #ffffff;
  margin: 18px 0 8px;
  font-size: 20px;
}

.box-timetable {
  font-family: 'Futura', sans-serif;
  font-weight: normal;
}

.box-phone-icon {
  margin: 24px 0;
  transition: all 0.3s ease;
}

.box-phone {
  font-size: 32px;
}

.box-phone-number:hover .box-phone-icon {
  transform: scale(1.2) rotate(6deg);
}

.box-fb-icon {
  transition: all 0.3s ease;
  transform: scale(0.8);
}

.box-facebook:hover .box-fb-icon {
  transform: scale(1);
}

@media (max-width: 960px) {
  .info-section .grid {
    grid-template-columns: repeat(3, minmax(200px, 260px));
    grid-auto-rows: minmax(200px, 1fr);
    grid-gap: 24px;
    grid-template-areas:
      'box1 box2 box3'
      'slider slider slider'
      'slider slider slider'
      'box4 box5 box6';
  }
}

@media (max-width: 740px) {
  .info-section .grid {
    grid-template-columns: repeat(2, minmax(200px, 260px));
    grid-auto-rows: minmax(200px, 1fr);
    grid-gap: 24px;
    grid-template-areas:
      'box1 box2'
      'box4 box3'
      'slider slider'
      'slider slider'
      'box5 box6';
  }

  .box-phone {
    font-size: 26px;
  }
}

@media (max-width: 500px) {
  .info-section .grid {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 20px;
    grid-template-areas:
      'box1'
      'box2'
      'slider'
      'box3'
      'box4'
      'box5'
      'box6';
  }

  .box-header {
    font-size: 32px;
  }

  .box-text {
    font-size: 26px;
  }

  .box-timetable {
    font-size: 20px;
  }

  .box-phone {
    font-size: 36px;
  }
}
