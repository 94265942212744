.pricing-section {
  margin: 80px 0;
}

.pricing-wrapper {
  display: flex;
  align-items: flex-start;
}

.pricing-wrapper > img {
  flex-shrink: 1;
  flex: 1 1 0;
  width: 0;
}

.pricing-list {
  flex: 1 1 0;
  flex-shrink: 0;
  margin-left: 24px;
}

@media (max-width: 499px) {
  .pricing-wrapper > img {
    display: none;
  }

  .pricing-list {
    margin-left: 0;
  }
}
