body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Futura', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p {
  line-height: 24px;
  color: #333333;
  text-align: center;
  margin: 26px 0;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#root,
.container {
  width: 100%;
  height: 100%;
}

/* FONTS */
@font-face {
  font-family: 'Futura';
  src: url('./assets/fonts/FuturaMedium.woff');
}
@font-face {
  font-family: 'Futura';
  src: url('./assets/fonts/FuturaMedium.woff');
}
@font-face {
  font-family: 'Lucida_Grande';
  src: url('./assets/fonts/LucidaGrande.woff');
}
