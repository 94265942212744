.modal {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-header {
  width: 100%;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  height: 40px;
  flex-shrink: 0;
}

.modal-header h2 {
  margin: 0;
  color: #ffffff;
  font-size: 16px;
}

.rodal-close {
  top: 20px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.rodal-close:hover {
  transform: translateY(-50%) scale(1.2);
}

.rodal-close:after,
.rodal-close:before {
  background: #ffffff;
  transition: all 0.3s ease;
}

.rodal-close:hover:after,
.rodal-close:hover:before {
  opacity: 0.8;
  background: #ffffff;
}

.modal-content {
  flex: 1;
  padding: 24px 20px;
  overflow: scroll;
}
