.section-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 36px;
}

.section-header h2 {
  margin: 0 0 16px;
  color: #333333;
  text-align: center;
}
